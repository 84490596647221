<template>
  <div class="cms-media-video">
    <component
      v-if="videoComponent"
      ref="video"
      :is="videoComponent"
      v-bind="$attrs"
      v-on="$listeners"
      :url="url"
    ></component>
    <div
      v-else
      class="empty-video"
    >{{ url ? 'URL inválida' : 'No hay URL' }}</div>
  </div>
</template>

<script>
import VideoYoutube from './Youtube/Youtube.vue';
import VideoVimeo from './Vimeo/Vimeo.vue';
import VideoMicrosoft from './Microsoft/Microsoft.vue';

export default {
  name: 'cms-media-video',

  components: {
    VideoYoutube,
    VideoVimeo,
    VideoMicrosoft,
  },

  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    videoComponent() {
      if (!this.url) {
        return null;
      }

      if (/(youtube\.|youtu\.)/.test(this.url)) {
        return 'VideoYoutube';
      }

      if (/(vimeo\.)/.test(this.url)) {
        return 'VideoVimeo';
      }

      if (/(microsoftstream\.com)/.test(this.url)) {
        return 'VideoMicrosoft';
      }

      return null;
    },
  },

  methods: {
    play() {
      this.$refs.video.play();
    },

    pause() {
      this.$refs.video.pause();
    },

    stop() {
      this.$refs.video.stop();
    },

    // Tiempo del video en milisegundos
    async getCurrentTime() {
      return this.$refs.video ? this.$refs.video.getCurrentTime() : null;
    },
  },
};
</script>

<style lang="scss">
.cms-media-video {
  .empty-video {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.08);
    min-height: 256px;
  }
}
</style>